.sweet-alert button {
  background-color: #5d9cec !important;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}

.sweet-alert button:hover {
  background-color: #3b87e8 !important;
}
